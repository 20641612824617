import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Modal,
  ModalDialog,
  Typography
} from '@mui/joy';

import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import debounce from '../../utils/debounce';
import { useSearchTrip } from '../../lib/trip';
import { useLocale } from '../../hooks/useLocale';

import { repathImg, REPOSITORIES } from '../../utils/repathImg';

export default function TopTripsModal({ open, onClose, push, currentTrips }) {
  const [locale] = useLocale();
  const [selectedTrips, setSelectedTrips] = useState([]);
  const [search, setSearch] = useState('');

  const { data, refetch } = useQuery(['allTrips'], () =>
    useSearchTrip({ search, filterIds: currentTrips })
  );

  useEffect(() => {
    refetch();
  }, [search]);

  const searchTrip = debounce((e) => {
    setSearch(e.target.value);
  }, 500);

  const selectedIds = selectedTrips.map((v) => v._id);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog sx={{ minWidth: '500px', maxWidth: '500px' }}>
        <Box
          sx={{
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px'
          }}>
          <DialogTitle>
            <Typography level="h4">Search trips</Typography>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Input placeholder="Climbing Mount Triglav" onChange={searchTrip} />
            </Box>
            <Box
              sx={{
                overflow: 'auto',
                maxHeight: '300px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                marginTop: '10px'
              }}>
              {data?.data?.map((trip) => (
                <Box
                  key={trip._id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    padding: '10px',
                    borderRadius: '8px',
                    backgroundColor: 'neutral.100',
                    cursor: 'pointer',
                    border: selectedIds.includes(trip._id)
                      ? '2px solid #0060c9'
                      : '2px solid transparent'
                  }}
                  onClick={() => {
                    if (selectedIds.includes(trip._id)) {
                      setSelectedTrips((prev) => prev.filter((v) => v._id !== trip._id));
                    } else {
                      setSelectedTrips((prev) => [...prev, trip]);
                    }
                  }}>
                  <img
                    alt={trip.i18n[locale].title}
                    src={`${repathImg(trip.cover_url, REPOSITORIES.TRIP_COVER, {
                      _id: trip._id
                    })}?w=180&h=180`}
                    height="50px"
                    width="50px"
                    loading="lazy"
                    style={{ borderRadius: '4px', objectFit: 'cover', marginRight: '5px' }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      alignItems: 'center'
                    }}>
                    <Typography>
                      {trip.i18n[locale].title} <Typography level="body-xs">#{trip._id}</Typography>
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </DialogContent>
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              justifyContent: selectedTrips.length > 0 ? 'space-between' : 'end'
            }}>
            {selectedTrips.length > 0 ? (
              <Button variant="plain" color="neutral">
                ({selectedTrips.length}) Remove selection
              </Button>
            ) : null}
            <DialogActions>
              <Button
                color="primary"
                onClick={() => {
                  selectedTrips.forEach((trip) => {
                    push(trip);
                  });
                  onClose();
                }}>
                Add trips
              </Button>
              <Button variant="plain" color="neutral" onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          </Box>
        </Box>
      </ModalDialog>
    </Modal>
  );
}
