import * as React from 'react';
import { Box, Typography, Card } from '@mui/joy';

import { shape, bool, string, func } from 'prop-types';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import ControlButtons from '../../components/PendingApproval/ControlButtons';

dayjs.extend(utc);

const isBeforeToday = (date) => dayjs(date).isBefore(dayjs());

const NextDepartures = ({ data, tripId, field, handleConfirm, updatingState }) => {
  const allDatesAreOld =
    data.length && data.every(({ departureDate }) => isBeforeToday(departureDate));

  if (allDatesAreOld)
    return (
      <Card>
        <Typography level="h5">Next departures</Typography>
        <Typography>All dates are outdated. Please confirm this change.</Typography>
        <ControlButtons
          to={`/trip/${tripId}/openGroup`}
          loading={updatingState.loading}
          handleConfirm={() => handleConfirm({ tripId, field })}
        />
      </Card>
    );

  return (
    <Card>
      <Typography level="h5">Next departures</Typography>
      <Box sx={{ marginTop: '5px' }}>
        {data.length ? (
          data
            .filter(({ departureDate }) => !isBeforeToday(departureDate))
            .map(({ departureDate, endDate, extra_info, _id, deletedAt }) => {
              return (
                <Box key={_id} sx={{ margin: '20px 0px' }}>
                  {deletedAt ? (
                    <Box>
                      <Typography level="body1" color="danger">
                        DELETED DATE
                      </Typography>
                    </Box>
                  ) : null}
                  <Typography level="body-sm" sx={{ marginBottom: '5px' }}>
                    <strong>Departure date</strong>:{' '}
                    {dayjs(departureDate).utc(false).format('DD/MM/YYYY')}
                  </Typography>
                  <Typography level="body-sm" sx={{ marginBottom: '5px' }}>
                    <strong>End date:</strong> {dayjs(endDate).utc(false).format('DD/MM/YYYY')}
                  </Typography>
                  <Typography level="body-sm" sx={{ marginBottom: '5px' }}>
                    <strong>Price:</strong> {extra_info.currency} {extra_info.price}
                  </Typography>
                </Box>
              );
            })
        ) : (
          <Box>
            <Typography level="body-sm" sx={{ fontStyle: 'italic' }}>
              All open groups were deleted
            </Typography>
          </Box>
        )}
      </Box>
      <ControlButtons
        to={`/trip/${tripId}/openGroup`}
        loading={updatingState.loading}
        handleConfirm={() => handleConfirm({ tripId, field })}
      />
    </Card>
  );
};

NextDepartures.propTypes = {
  data: shape.isRequired,
  tripId: string.isRequired,
  field: string.isRequired,
  handleConfirm: func.isRequired,
  updatingState: {
    loading: bool
  }.isRequired
};

export default NextDepartures;
