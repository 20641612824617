const ASSETS_URL = import.meta.env.VITE_ASSETS_URL;

export const REPOSITORIES = {
  GUIDE: 'GUIDE',
  TRIP_COVER: 'TRIP_COVER',
  TRIP: 'TRIP',
  LANDING_PAGE: 'LANDING_PAGE',
  LANDING_PAGE_RELEVANT: 'LANDING_PAGE_RELEVANT',
  STATIC_PAGE_HEADER: 'STATIC_PAGE_HEADER',
  STATIC_PAGE: 'STATIC_PAGE',
  // statics assets
  STATICS_LOGO: 'STATICS_LOGO',
  STATICS_OTHER_LOGO: 'STATICS_OTHER_LOGO',
  STATICS_ICON: 'STATICS_ICON',
  STATICS_FACE: 'STATICS_FACE',
  STATICS_PAGE_ABOUT: 'STATICS_PAGE_ABOUT',
  STATICS_PAGE_PRESS: 'STATICS_PAGE_PRESS',
  STATICS_PAGE_HOME: 'STATICS_PAGE_HOME',
  STATICS: 'STATICS',
  AUTHOR: 'AUTHOR'
};

export const DEFAULT_IMAGE = `${ASSETS_URL}/statics/default-trip-cover`;

const pathBuilder = (repository, params = { _id: null }) => {
  const paths = {
    [REPOSITORIES.GUIDE]: `guides/${params._id}/avatar`,
    [REPOSITORIES.TRIP]: `trips/${params._id}/photos`,
    [REPOSITORIES.TRIP_COVER]: `trips/${params._id}/cover`,
    [REPOSITORIES.LANDING_PAGE]: `landing_page/${params._id}/header`,
    [REPOSITORIES.LANDING_PAGE_RELEVANT]: `landing_page/${params._id}/relevant`,
    [REPOSITORIES.STATIC_PAGE_HEADER]: `static_page/${params._id}/header`,
    [REPOSITORIES.STATIC_PAGE]: `static_page/${params._id}/photo`,
    [REPOSITORIES.AUTHOR]: `authors/${params._id}/avatar`,

    // statics assets
    [REPOSITORIES.STATICS_OTHER_LOGO]: `statics/other_logos`,
    [REPOSITORIES.STATICS_LOGO]: `statics/logos`,
    [REPOSITORIES.STATICS_ICON]: `statics/icons`,
    [REPOSITORIES.STATICS_FACE]: `statics/faces`,
    [REPOSITORIES.STATICS_PAGE_ABOUT]: `statics/page/about`,
    [REPOSITORIES.STATICS_PAGE_PRESS]: `statics/page/press`,
    [REPOSITORIES.STATICS_PAGE_HOME]: `statics/page/home`,
    [REPOSITORIES.STATICS]: `statics`
  };

  return paths[repository];
};

/**
 * restructure datocms image url to assets url
 * @param {string} originalUrl  // datocms url
 * @param {string} repository
 * @param {object} params
 * @returns assets url
 */
export const repathImg = (originalUrl, repository, params) => {
  // default image
  if (!originalUrl) return DEFAULT_IMAGE;
  // if url is new assets cdn
  if (originalUrl.includes(ASSETS_URL)) return originalUrl;

  const fileName = originalUrl.split('/').pop();
  const newpath = pathBuilder(repository, params);

  return `${ASSETS_URL}/${newpath}/${fileName}`;
};

/**
 * contruct a assets url
 * @param {string} file // this file must exists in assets
 * @param {string} repository
 * @param {object} params
 * @returns assets url
 */
export const pathImg = (file, repository, params) => {
  // default image
  if (!file) return DEFAULT_IMAGE;

  const newpath = pathBuilder(repository, params);

  return `${ASSETS_URL}/${newpath}/${file}`;
};
