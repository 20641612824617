import { Box, Input, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { useGetGuides } from '../../lib/guide';
import { repathImg, REPOSITORIES } from '../../utils/repathImg';

export default function Guide({ guide, setFieldValue }) {
  const [newGuide, setNewGuide] = useState(null);
  const [text, setText] = useState('');
  const { data: allGuides } = useQuery(['all-guides'], () => useGetGuides());

  const guidesToShow =
    text.length > 3
      ? allGuides?.filter((g) => {
          const fullName = `${g.first_name} ${g.last_name}`;
          return fullName.toLowerCase().includes(text.toLowerCase());
        })
      : [];

  const currentGuide = newGuide || guide;

  return (
    <Box>
      <Typography level="title-md">Current guide:</Typography>
      {currentGuide ? (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', margin: '20px 0px' }}>
          <img
            alt="guide"
            src={`${repathImg(currentGuide.avatar_url, REPOSITORIES.GUIDE, {
              _id: currentGuide._id
            })}?w=50&h=50&q=40`}
            height={50}
            width={50}
            style={{ objectFit: 'cover' }}
          />
          <Typography>
            {currentGuide.first_name} {currentGuide.last_name}
          </Typography>
        </Box>
      ) : (
        <Typography>No guide assigned</Typography>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '50px' }}>
        <Typography level="title-md">Assign new guide</Typography>
        <Input value={text} placeholder="Search guide" onChange={(e) => setText(e.target.value)} />
        <Box>
          {guidesToShow?.map((g) => (
            <Box
              key={g._id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                marginTop: '20px',
                cursor: 'pointer'
              }}
              onClick={() => {
                setNewGuide(g);
                setFieldValue('guide', { _id: g._id });
                setText('');
              }}>
              <img
                alt="guide"
                src={`${repathImg(g.avatar_url, REPOSITORIES.GUIDE, {
                  _id: g._id
                })}?w=50&h=50&q=40`}
                height={50}
                width={50}
                style={{ objectFit: 'cover' }}
              />
              <Typography>
                {g.first_name} {g.last_name}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
